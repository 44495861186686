import React from "react";
import NavbarHome from "../../components/navbar/NavbarHome";
import ImageGallery from "../../components/imageGallery/ImageGallery";

const Gallery = () => {
  return (
    <div>
      <NavbarHome />
      <ImageGallery />
    </div>
  );
};

export default Gallery;
