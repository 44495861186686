import React from "react";
import NavbarHome from "../../components/navbar/NavbarHome";
import { Typography } from "@mui/material";
const UpcomingEvents = () => {
  return (
    <>
      <NavbarHome />
      <div>
        <Typography variant="h2" gutterBottom>
          Events
        </Typography>
      </div>
    </>
  );
};

export default UpcomingEvents;
