import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./components/common/Layout";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Sponsors from "./pages/Sponsors/Sponsors";
import ContactUs from "./pages/ContactUs/ContactUs";
import Gallery from "./pages/Gallery/Gallery";
import UpcomingEvents from "./pages/Events/UpcomingEvents";

function App() {
  return (
    <>
      <div className="App container">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* public routes */}
              <Route path="/" element={<Home />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/UpcomingEvents" element={<UpcomingEvents />} />
              <Route path="/Sponsors" element={<Sponsors />} />
              <Route path="/ContactUs" element={<ContactUs />} />
              <Route path="/Gallery" element={<Gallery />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>

      {/*
        <div className="App">
        
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          </header>
        </div> 
      */}
    </>
  );
}

export default App;
