import React from "react";
import NavbarHome from "../../components/navbar/NavbarHome";
import { Typography } from "@mui/material";

const ContactUs = () => {
  return (
    <>
      <NavbarHome />
      <Typography variant="h2" gutterBottom>
        Contact Us
      </Typography>
    </>
  );
};

export default ContactUs;
