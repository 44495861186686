import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "IMAN Concert 2024",
    imgPath: "events_1",
  },
  {
    label: "IMAN Concert 2024",
    imgPath: "events_2",
  },
];

const ImageCarousel = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <>
      <Typography
        gutterBottom
        align="center"
        //variant={{ xs: "h3", sm: "h3" }}
        sx={{
          font: "Roboto",
          color: "var(--clr-bronze)",
          backgroundColor: "lightcyan",
          fontWeight: 500,
          fontSize: { xs: 30, sm: 35, md: 40, lg: 40 },
          fontWeight: 300,
        }}
      >
        <b>Upcoming Events</b>
      </Typography>
      <Box sx={{ width: "100%", backgroundColor: "#e1e8cf" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/*<Grid item xs={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
            <Typography variant="h3" gutterBottom>
              Summer Picnic 2024
            </Typography>
            <Typography variant="h5" gutterBottom>
              Springbank Park, Site K, London, ON
            </Typography>
            <Typography variant="h5" gutterBottom>
              1085 Commissioners Rd W, London, ON N6K 4Y6, Canada
            </Typography>
            <Typography variant="h5" gutterBottom>
              Sat, June 29, 2024 11:00 a.m. - 05:00 p.m.EDT
            </Typography>
            <Button
              variant="contained"
              color="success"
              onClick={() =>
                window.open(
                  "https://www.zeffy.com/en-CA/ticketing/e20606de-0443-478e-b7bb-42e82f397d53"
                )
              }
            >
              Enroll for Picnic
            </Button>
            </Grid>*/}

          <Grid item xs={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
            <Typography variant="h5" gutterBottom>
              Durga Puja 2024
            </Typography>
            <Typography variant="subtitle1">
              Sir Frederick Banting Secondary School
            </Typography>
            <Typography variant="subtitle2">
              125 Sherwood Forest Square, London, ON N6G 2C3
            </Typography>
            <Typography variant="h6" gutterBottom>
              October 12-13, 2024 from 11:00 a.m.
            </Typography>
            <Button
              variant="contained"
              color="success"
              onClick={() =>
                window.open(
                  "https://www.zeffy.com/en-CA/ticketing/ae8bc303-8bfc-463c-8b49-eed259c0153b"
                )
              }
            >
              Enroll for Concert
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                window.open(
                  "https://www.zeffy.com/en-CA/embed/ticketing/7e6bb198-58a5-474a-81f2-9266b4634ccb"
                )
              }
            >
              Become a Member
            </Button>
          </Grid>

          <Grid xs={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
            <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
              <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 50,
                  pl: 2,
                  bgcolor: "background.default",
                }}
              >
                <Typography>{images[activeStep].label}</Typography>
              </Paper>
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: 500,
                          display: "block",
                          maxWidth: "100%",
                          overflow: "hidden",
                          width: "100%",
                        }}
                        src={require(`../../images/events/${step.imgPath}.png`)} //{step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
          </Grid>
          <Grid xs={{ xs: 12, sm: 12, md: 6, lg: 6 }}></Grid>
          <Grid xs={{ xs: 12, sm: 12, md: 6, lg: 6 }}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ImageCarousel;
