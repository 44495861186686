import React from "react";
import NavbarHome from "../../components/navbar/NavbarHome";
import Hero from "../../components/hero/Hero";
import Footer from "../../components/footer/Footer";
import ImageGallery from "../../components/imageGallery/ImageGallery";
import About from "../../components/aboutUs/About";
import ImageCarousel from "../../components/imageGallery/ImageCarousel";
import ImageGalleryButton from "../../components/imageGallery/ImageGalleryButton";

const Home = () => {
  return (
    <>
      <NavbarHome />
      <Hero />
      <ImageCarousel />
      <ImageGallery />
      <About />
      <Footer />
    </>
  );
};

export default Home;
