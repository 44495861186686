import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AccessTime,
  Call,
  Email,
  Facebook,
  Instagram,
  YouTube,
  Menu as MenuIcon,
  Twitter,
} from "@mui/icons-material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
//import HeroImage from "../../images/utsav_home.jpg";
import HeroImage from "../../images/banners/DurgaPuja2024.JPG";

const NavbarHome = () => {
  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#193658",
  });
  const MenuBox = styled(Box)({
    display: "flex",
    gap: 30,
  });
  const SocialBox = styled(Box)({
    display: "flex",
    gap: 10,
  });
  const MenuItems = [
    { Name: "Home", Link: "/" },
    { Name: "About Us", Link: "/AboutUs" },
    { Name: "Upcoming Events", Link: "/UpcomingEvents" },
    { Name: "Sponsors", Link: "/Sponsors" },
    { Name: "Picture Gallery", Link: "/Gallery" },
    { Name: "Contact Us", Link: "/ContactUs" },
  ];
  const [openMenu, setOpenMenu] = useState(false);
  let navigate = useNavigate();
  return (
    <AppBar position="static">
      <StyledToolbar>
        <Box>
          <Avatar
            alt="Utsav Bengali Association"
            src={require("../../images/logo-png.png")}
            sx={{
              cursor: "pointer",
              height: { xs: 40, sm: 40, md: 40, lg: 50 },
              width: { xs: 40, sm: 40, md: 40, lg: 50 },
            }}
            onClick={() => navigate("/", { replace: true })}
          />
        </Box>
        {/*<Box sx={{ display: { xs: "none", sm: "flex" } }}>
          <Stack direction="column" textAlign="left">
            <Typography variant="subtitle2">
              <Call fontSize="small" />
              &nbsp;Contact Us: 226-224-4091 | 226-926-0710
            </Typography>
            <Typography variant="subtitle2" textAlign="left">
              <Email fontSize="small" />
              &nbsp;utsavonation@gmail.com
            </Typography>
          </Stack>
        </Box>*/}
        {/*<MenuBox sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
          {MenuItems.map((item) => (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(`${item.Link}`, { replace: false })}
            >
              {item.Name}
            </Typography>
          ))}
        </MenuBox>*/}
        <SocialBox>
          <Facebook
            sx={{
              cursor: "pointer",
              height: { xs: 20, sm: 30, md: 40, lg: 50 },
              width: { xs: 20, sm: 30, md: 40, lg: 50 },
            }}
            onClick={() => {
              window.open("https://www.facebook.com/utsavontario");
            }}
          ></Facebook>
          <Twitter
            sx={{
              cursor: "pointer",
              height: { xs: 20, sm: 30, md: 40, lg: 50 },
              width: { xs: 20, sm: 30, md: 40, lg: 50 },
            }}
            onClick={() => {
              window.open("http://twitter.com/BengaliLndOnt");
            }}
          />
          <Instagram
            sx={{
              cursor: "pointer",
              height: { xs: 20, sm: 30, md: 40, lg: 50 },
              width: { xs: 20, sm: 30, md: 40, lg: 50 },
            }}
            onClick={() => {
              window.open("https://www.instagram.com/utsavontario/");
            }}
          />
          <YouTube
            sx={{
              cursor: "pointer",
              height: { xs: 20, sm: 30, md: 40, lg: 50 },
              width: { xs: 20, sm: 30, md: 40, lg: 50 },
            }}
            onClick={() => {
              window.open(
                "https://youtube.com/@UtsavBengaliAssociation?si=pB6f128k3vyte6PV"
              );
            }}
          />
        </SocialBox>
        <Box>
          <Stack direction="row" verticalAlign="middle">
            <Button
              variant="outlined"
              size="small"
              backgroundColor="white"
              sx={{
                color: "orange",
                borderColor: "orange",
                margin: "1px",
              }}
              id="memebership-button"
              onClick={() =>
                window.open(
                  "https://www.zeffy.com/en-CA/embed/ticketing/7e6bb198-58a5-474a-81f2-9266b4634ccb"
                )
              }
            >
              <b>Become A Member</b>
            </Button>

            {/*<Button
              variant="Filled"
              backgroundColor="white"
              color="secondary"
              endIcon={<LockOpenIcon />}
              id="login-button"
              onClick={() => navigate("/", { replace: true })}
            >
              Login
          </Button>*/}
            <Button
              variant="outlined"
              size="large"
              backgroundColor="white"
              sx={{
                color: "white",
                borderColor: "white",
                margin: "4px",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "inline-block",
                  lg: "inline-block",
                },
              }}
              onClick={() => setOpenMenu(!openMenu)}
            >
              <b>Menu</b>
            </Button>
            <MenuIcon
              size="large"
              sx={{
                color: "white",
                verticalAlign: "",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
              }}
              onClick={() => setOpenMenu(!openMenu)}
            />
          </Stack>
        </Box>
      </StyledToolbar>
      <Menu
        id="basic-menu"
        open={openMenu}
        onClose={() => setOpenMenu(!openMenu)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {" "}
        <Box sx={{ width: 200, backgroundColor: "#193658" }}>
          {MenuItems.map((item) => (
            <MenuItem
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Typography
                sx={{ cursor: "pointer", color: "white" }}
                onClick={() => navigate(`${item.Link}`, { replace: false })}
              >
                <b>{item.Name}</b>
              </Typography>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </AppBar>
  );
};

export default NavbarHome;
