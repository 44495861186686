import React, { useState } from "react";

import NavbarHome from "../../components/navbar/NavbarHome";
import "../../styles/gallery.css";
import { Box, Typography } from "@mui/material";
import s1 from "../../images/sponsors/s1.jpeg";
import s2 from "../../images/sponsors/s2.jpeg";
import s3 from "../../images/sponsors/s3.jpeg";
import s4 from "../../images/sponsors/s4.jpeg";
import s5 from "../../images/sponsors/s5.jpeg";
import s6 from "../../images/sponsors/s6.jpeg";
import s7 from "../../images/sponsors/s7.jpeg";
import s8 from "../../images/sponsors/s8.jpeg";
import s9 from "../../images/sponsors/s9.jpeg";
import s10 from "../../images/sponsors/s10.jpeg";
import s11 from "../../images/sponsors/s11.jpeg";

import { Close } from "@mui/icons-material";
const Sponsors = () => {
  let SponsorsData = [
    {
      id: 1,
      imgsrc: s1,
    },
    {
      id: 2,
      imgsrc: s2,
    },
    {
      id: 3,
      imgsrc: s3,
    },
    {
      id: 4,
      imgsrc: s4,
    },
    {
      id: 5,
      imgsrc: s5,
    },
    {
      id: 6,
      imgsrc: s6,
    },
    {
      id: 7,
      imgsrc: s7,
    },
    {
      id: 8,
      imgsrc: s8,
    },
    {
      id: 9,
      imgsrc: s9,
    },
    {
      id: 10,
      imgsrc: s10,
    },
    {
      id: 11,
      imgsrc: s11,
    },
  ];
  const [modal, setModal] = useState(false);
  const [teemimgSrc, setTeemimgSrc] = useState("");
  const getImg = (imgSrc) => {
    {
      setTeemimgSrc(imgSrc);
      setModal(true);
    }
  };
  return (
    <>
      <NavbarHome />
      <Box
        sx={{
          backgroundColor: "#e1e8cf",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h4" color="brown" textAlign="center" gutterBottom>
          Our Sponsors
        </Typography>
        <div className={modal ? "modal open" : "modal"}>
          <img src={teemimgSrc} />
          <Close onClick={() => setModal(false)} />
        </div>
        <div className="gallery">
          {SponsorsData.map((item, index) => {
            return (
              <div
                className="pics"
                key={index}
                onClick={() => getImg(item.imgsrc)}
              >
                <img src={item.imgsrc} style={{ width: "100%" }} />
              </div>
            );
          })}
        </div>
      </Box>
    </>
  );
};

export default Sponsors;
