import React from "react";
import NavbarHome from "../../components/navbar/NavbarHome";
import About from "../../components/aboutUs/About";

const AboutUs = () => {
  return (
    <>
      <NavbarHome />
      <About />
    </>
  );
};

export default AboutUs;
